
//tooltips
$(document).ready(function(){
	$('[data-toggle="tooltip"]').tooltip();   


	/* MODALS */
	const $cri1 = document.querySelector('.cri-1');
	const $cri2 = document.querySelector('.cri-2');
	const $cri3 = document.querySelector('.cri-3');
	const $cri4 = document.querySelector('.cri-4');
	const $cri5 = document.querySelector('.cri-5');
	const $cri6 = document.querySelector('.cri-6');
	const $cri7 = document.querySelector('.cri-7');
	const $cri8 = document.querySelector('.cri-8');
	
	const $closeCri1 = document.querySelector('.close1');
	const $closeCri2 = document.querySelector('.close2');
	const $closeCri3 = document.querySelector('.close3');
	const $closeCri4 = document.querySelector('.close4');
	const $closeCri5 = document.querySelector('.close5');
	const $closeCri6 = document.querySelector('.close6');
	const $closeCri7 = document.querySelector('.close7');
	const $closeCri8 = document.querySelector('.close8');

	function showModal(id) {
		const $modal = document.querySelector(id);
		$modal.classList.toggle('active');
	}
	function hideModal(id) {
		const $modal = document.querySelector(id);
		$modal.classList.toggle('active', false);
	}


	// Get the image and insert it inside the modal
	$cri1.addEventListener('click', function() { showModal('.md1'); });
	$cri2.addEventListener('click', function() { showModal('.md2'); });
	$cri3.addEventListener('click', function() { showModal('.md3'); });
	$cri4.addEventListener('click', function() { showModal('.md4'); });
	$cri5.addEventListener('click', function() { showModal('.md5'); });
	$cri6.addEventListener('click', function() { showModal('.md6'); });
	$cri7.addEventListener('click', function() { showModal('.md7'); });
	$cri8.addEventListener('click', function() { showModal('.md8'); });

	$closeCri1.addEventListener('click', function() { hideModal('.md1'); });
	$closeCri2.addEventListener('click', function() { hideModal('.md2'); });
	$closeCri3.addEventListener('click', function() { hideModal('.md3'); });
	$closeCri4.addEventListener('click', function() { hideModal('.md4'); });
	$closeCri5.addEventListener('click', function() { hideModal('.md5'); });
	$closeCri6.addEventListener('click', function() { hideModal('.md6'); });
	$closeCri7.addEventListener('click', function() { hideModal('.md7'); });
	$closeCri8.addEventListener('click', function() { hideModal('.md8'); });
	
	const dataAtual = new Date();
	const anoAtual = dataAtual.getFullYear();

	document.querySelector('#year').innerHTML = anoAtual
});



// FORNECEDORES

var timerid = 0;
var images = new Array(	"img/forn1.jpg",
		"img/forn2.jpg",
		"img/forn3.jpg");
var countimages = 0;

function startTime()
{
	if(timerid)
	{
		timerid = 0;
    }
    
	var tDate = new Date();
	
	if(countimages == images.length)
	{
		countimages = 0;
	}
	if(tDate.getSeconds() % 20 == 0)
	{
		document.getElementById("forn").src = images[countimages];
	}
	countimages++;
	
	timerid = setTimeout(startTime, 1000);
}

// MENU

